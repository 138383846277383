$('#flash-overlay-modal').modal();

$('#exampleModal').on('show.bs.modal', function (event) {
    var link = $(event.relatedTarget)

    var data = link.data('type')
    var info = link.data('info');

    switch(data) {
        case 'email':
            info = 'E-Mail Address: <a href="mailto:' + info + '">' + info + '</a>';
            break;
        case 'website':
            info = 'Website: <a href="' + info + '">' + info + '</a>';
            break;
        case 'phone':
            info = 'Phone: ' + info;
            break;
    }

    $('#info').html(info)
});

//$('#descriptionModal').on('show.')